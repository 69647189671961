#plan-container {
  margin-top: 1em;
  margin-bottom: 1em;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #CCC;
  padding: 2em 4em;
}

#plan-title {
  display: flex;
  /* align-items: center; */
   text-align: center;
}

.long-plan-title{
  text-align: start !important;
  font-weight: normal !important;
  margin-top: 0.5em !important;
}

.plan-header {
  margin-bottom: .3em;
  font-weight: bold;
  font-size: 1.3em;
}

.plan-cover-heading{
  font-weight: bold;
  font-size: 1.75em;
  text-align: left;
  margin-top: 2em;
}

.go-to-plan-from-cover{
  position: fixed;
  top: 40%;
  left: 80%;
  font-size: 8em;
  cursor: pointer;  
  color: #cccccc;
  opacity: 0.5;
}

.go-to-plan-from-cover:hover{
  opacity: 1;
}

.disable-button{
  cursor: not-allowed;
  background-color: #ebebeb !important;
}

.plan-cover-container{
  min-height: 80vh;
}

.custom-plan-heading{
  color: var(--custom-heading-color);
}

.trial-watermark{
  opacity: 0.1;
  position:absolute;
  top:0em;
  left:0em;
  font-size: 6rem;
  width: 100%;
  display: flex;
  justify-content: center;
}

#plan-title-text {
  padding-right: 70px;
  max-width: 100%;
  margin: auto;
  overflow: hidden;
  /* max-height: 10em; */
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  font-weight: 700;
  color: var(--enterprise-secondary-color)

}

#printButton{
  color: var(--enterprise-primary-color);
  cursor:pointer;
  margin-bottom: 0.5em; 
  font-weight: bold;
  font-size: 1.2rem;
}

#printButton:hover{
  opacity:0.8;
  
}

.product-content-block:hover{
  color:#fff;
  background-color: var(--enterprise-primary-color);
  border-radius: 5px;
  font-weight: bold;
}

/* .product-content-block:hover{
  border-bottom: 1px solid var(--enterprise-primary-color);
  margin-bottom:-1px;
} */

.product-content-block{
  align-items: center;
  padding-top: 0.25em;
}

.product-headers-row{
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 0.5em;
}

.product-summary-row{
  margin-top: 0.5em;
  padding-top: 0.5em;
  border-top: 1px solid #dee2e6;
}

.plan-comment-section{
  margin-bottom: -2em;
  margin-left: auto;
  margin-right: -3em;
  float: right;
}
.annotation-container{
  position: fixed;
  margin-top: 1em;
  background-color: #fff;
  border-radius: 5px 25px 5px 5px;
  box-shadow: 0 0 5px #ccc;
  padding:1.25em;
  right:2em;
  background-color: #fff;
}
.comment-container{
  /* position: fixed; */
  height: 100%;
  width: inherit;
  margin-left: 0.5em;
  margin-top: 1em;
  margin-bottom: 1em;
  /* background-color: #fff; */
}

.scroll-to-top-button{
  display:none;
  font-size: 2rem;
  position:fixed;
  bottom: 1em;
  right: 1em;
  color: #fff;
  background-color: var(--enterprise-highlight-color);
  cursor: pointer;
  z-index: 12;
  border:none;
  border-radius: 100%;
  padding:0 0.3em;
  box-shadow: 0 0 5px #ccc;
}

.scroll-to-top-button:hover{
  opacity:0.8;
}

.modal-name-overflow-handler{
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20em;
  white-space: nowrap;
}

.planAttachment-name-overflow-handler{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.view-content-icon {
  border: 1px solid #404040;
  border-radius: 50%;
  cursor: pointer;
  min-width: 40px;
  min-height: 40px;
  text-align: center;
  padding: 10px;
  margin-right: 1em;
}

.view-content-icon.active, .view-content-icon:hover {
  border: 1px solid var(--highlight-color) !important;
  background-color: var(--highlight-color) !important;
  color: #fff;
}

.deactive-view-content-icon {
  border: 1px solid #404040;
  border-radius: 50%;
  cursor: pointer;
  min-width: 40px;
  min-height: 40px;
  text-align: center;
  padding: 10px;
  margin-right: 1em;
}

.additional-content {
  margin-top: 1em;
}

.additional-content-row {
  display: flex;
  padding: .5em 0;
  cursor: pointer;
}

.additional-content-row:hover {
  background-color: rgba(204, 204, 204, 0.2);
}

.sign-row{
  margin-top: 1em;
}

.underLine{
  margin-bottom: -1px;
  border-bottom: 1px solid black;
}

.signature-container{
  margin-right: 2em;
  max-width: 20em;
}

@font-face {
  font-family: 'brush-script-mt';
  src: url('../../fonts/BRUSHSCI.woff') format('woff');
}
.signed-name{
 font-family: 'brush-script-mt' , Fallback, sans-serif;
  /* font-style: italic; */
  color: rgb(29, 1, 94);
  font-size: 1.5rem;
}

.sign-plan-button{
  background-color: var(--enterprise-primary-color) !important;
  color: #fff !important;
  font-weight: bold !important;
}

.amcharts-Bullet, .amcharts-PointedCircle-group, .amcharts-RoundedRectangle-group {
  cursor: pointer;
  
}

.amcharts-RoundedRectangle-group {
  z-index: 88;
}
/*summary*/

.ellipsis-style{
  color: var(--enterprise-secondary-color);
}

.summaryToggler{
  color: var(--summary-header);
}

.summary-item {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.summary-topic {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.summary-topic:after {
  content: " ..........................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................."
}

.summary-list-index {
  flex-shrink: 0;
}

.summary-item:hover {
    background-color: var(--enterprise-primary-color);
    color: var(--enterprise-highlight-font-color);
    font-weight:700;
    border-radius: 5px;
    margin-left:-0.25em;
    padding-left: 0.25em;
    margin-right:-0.25em;
    padding-right: 0.25em;
}
/**/

/*Embed related*/
.dashboard-medium-box {
  border-radius: 5px;
  background-color: #fff;
  padding: 1rem 0;
  margin-top: 1rem;
  box-shadow: 0 3px 6px #CCC;
}

.box-header {
  padding: 0 0 1rem 1rem;
  border-bottom: 1px solid #b5e413;
  min-height: 55px;
}

.bigger-text {
  font-size: 1.2rem;
  font-weight: 600;
}

.small-white-button {
  -webkit-box-shadow: 0px 0px 3px 0px rgba(204,204,204,1);
  -moz-box-shadow: 0px 0px 3px 0px rgba(204,204,204,1);
  box-shadow: 0px 0px 3px 0px rgba(204,204,204,1);

  border-radius: 5px;
  background-color: #fff;
  border: none;
  padding: 0.35rem;
  color: rgb(47, 47, 47);
  /* min-width: 6.5rem; */
}

.small-white-button:hover {
  opacity: 0.7;
}

.lighter-text {
  font-size: 0.8rem;
  font-weight: 300;
}

.wide-button {
  padding-right: 2em;
  padding-left: 2em;
  white-space: nowrap !important;
}

.wide-button.active {
  background-color: #BDE533 !important;
  color: #FFF;
  font-weight: 700;
}

.odd-row {
  padding: 0.5rem;
  /* padding-left: 2rem; */
}

.even-row {
  background-color: #F5F5F5;
  padding: 0.5rem;
  /* padding-left: 2rem; */
}

.selectable-row {
  cursor: pointer;
  color: #404040 !important;
  text-decoration: none !important;
}

.selectable-row:hover {
  background-color: #efefef;
  text-decoration: none !important;
}

.trello-label-row {
  width: 100%;
}

.trello-label {
  background-color: #b3bac5;
  border-radius: 4px;
  color: #fff;
  display: block;
  margin-right: 4px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  float: left;
  margin: 0 4px 4px 0;
  min-width: 60px;
  text-shadow: none;
  width: auto;
  height: 16px;
  line-height: 14px;
  padding: 0 8px;
  max-width: 198px;
  text-align: center;
}

.trello-label-text {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}

.trello-label-green {
    background-color: #61bd4f;
}

.trello-label-yellow {
    background-color: #f2d600;
}

.trello-label-orange {
    background-color: #ff9f1a;
}

.trello-label-red {
    background-color: #eb5a46;
}

.trello-label-purple {
    background-color: #c377e0;
}

.trello-label-blue {
    background-color: #0079bf;
}

.trello-label-black {
    background-color: #344563;
}

.trello-label-lime {
    background-color: #51e898;
}

.trello-label-pink {
    background-color: #ff78cb;
}

.trello-member {
  float: right;
  background-color: #dfe1e6;
  border-radius: 25em;
  color: #172b4d;
  cursor: pointer;
  display: block;
  margin: 0 4px 4px 0;
  overflow: visible;
  position: relative;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 0;
  height: 28px;
  line-height: 28px;
  width: 28px;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
}
/**/

@media only screen and (max-width: 640px){
  .risk-matrice-col{
    padding:0 !important;
    margin-left: -10em;
    width: 200% !important;
  }
}

@media only screen and (max-width: 823px) {
  
  #plan-container {
    padding: 2em 1em !important;
    margin: top 3px;
    margin-bottom:0;
  }

  #signature-col{
    margin-top:1em;
    padding-top: 1em;
  }

  #plan-title {
    flex-direction: column;
    margin-bottom: 1em;
    text-align: start;
    align-items: flex-start;
  }

  #plan-title-image {
    margin-bottom: .5em;
  }

  #plan-title-text {
    padding-right: 0 !important;
    max-width: 100% !important;
    /* overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; */
  }
  .go-to-plan-from-cover{
    left: 90% !important;
  }

}

@media only screen and (max-width: 1024px) {

  #plan-col {
    margin-left:0 !important;
  }
  
  #plan-container {
    padding: 4em 1em !important;
  }

  #signature-col{
    margin-top:1em;
    padding-top: 1em;
  }

  #plan-title {
    flex-direction: row;
    margin-bottom: 1em;
  }

  #plan-title-image {
    margin-bottom: .5em;
  }

  #plan-title-text {
    padding-right: 0 !important;
    max-width: 100% !important;
  }
  main{
    padding-top:0;
  }
  #plan-title{
    flex-wrap: wrap;
  }
  .go-to-plan-from-cover{
    left: 95% ;
  }
}

@media only screen and (max-width: 640px) {
  
  .amcharts-Sprite-group .amcharts-Container-group .amcharts-Container .amcharts-CategoryAxis-group{
    display:none 
  } 
  
}
