:root{
  --highlight-color: #6ba53f;
  --enterprise-highlight-color: #6ba53f;
  --enterprise-background-color: #fff;
  --enterprise-highlight-font-color: #fff;
  --enterprise-title-color: #212529;
  --enterprise-background-image: url('assets/images/EB_background.png');
  --summary-header: var(--enterprise-title-color);
  --enterprise-secondary-color: #212529;
  --enterprise-primary-color:#6ba53f;
  --custom-heading-color: #404040;
  --EB-primary-color: #6ba53f;
}

html, body, #root {
  min-height: 100%;
  height: 100%;
}

body {
  background-image: var(--enterprise-background-image);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  font-family: 'Raleway', sans-serif !important;
}

main {
  padding-top: 70px;
  min-height: 100%;
  height: 100%;
}

a:hover {
  text-decoration: none !important;
}

/*GREYBOX FOR LOGIN AND REGISTER FORM*/
.greybox {
  -webkit-box-shadow: 7px 10px 6px -4px rgba(74,74,74,0.66);
  -moz-box-shadow: 7px 10px 6px -4px rgba(74,74,74,0.66);
  box-shadow: 7px 10px 6px -4px rgba(74,74,74,0.66); 
  padding: 50px !important;
  background-color: rgba(132, 152, 173, 0.6);
}

.greybox0 {
  -webkit-box-shadow: 7px 10px 6px -4px rgba(74,74,74,0.66);
  -moz-box-shadow: 7px 10px 6px -4px rgba(74,74,74,0.66);
  box-shadow: 7px 10px 6px -4px rgba(74,74,74,0.66); 
  background-color: rgba(132, 152, 173, 0.6);
}

.login-box{
  -webkit-box-shadow:0 3px 6px #CCC; 
  -moz-box-shadow: 0 3px 6px #CCC; 
  box-shadow: 0 3px 6px #CCC; 
  /* background-color:#fff; */
  border-radius: 10px;
  background-color: var(--EB-primary-color);
}

.login-box-header {
  font-size: x-large;
  padding: 15px;
  text-align: center;
}

.login-inner-container{
  border-radius: 30px;
  background-color: #fff;
  padding-left: 4em;
  padding-right: 4em;
  padding-bottom: 2em;
  margin-left: -15px;
  margin-right: -15px;
}

.ever-better-logo{
  font-family: sans-serif;
  color: var(--EB-primary-color);
  font-size: 2.5rem;
  text-align: center;
}

.confirm-button{
  background-color: var(--EB-primary-color) !important;
  color: #fff !important;
  font-weight: bold;
}

.go-back-link{
  color:#001edf !important;
  text-decoration: underline !important;
}

.member-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.checklist-numbers {
  font-size: 0.9em;
  opacity: 0.6;
  font-weight: 700;
  padding-left: 0.5em;
}

/* Dropzone */
.drop-zone{
  min-height: 10em;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  border: 1px dashed #969696;
  background-color: #eeeeee;
  opacity:0.8;
}
.drop-container{
  padding-bottom: 1em;
}

#alternativeHeader{
  display: block;
  z-index:100;
  position:absolute;
  top:0;
  right:0;
  left: auto;
  width: 6em;
  border-radius: 0 0 0 10px;
}
.alternative-header-nav{
  border-radius: 0 0 0 10px !important;
}

/* FORM */
form.white_input .form-group label {
	color:#fff;
  font-size: 1.2em;
  font-weight: 600;
}
form.white_input .form-group input {
	color:#fff;
	background-color: transparent !important;
	border:0px;
	border-bottom: 1px solid #fff;
	opacity: 0.7;
}

form.white_input .form-group input.is-invalid {
	 animation: pulse 2s 2 !important;
}


form.white_input .form-group input:focus, form.white_input .form-group input:active, form.white_input  .form-group input:hover {
	border:0px;
	border-bottom: 1px solid #fff;
	box-shadow: none;
	opacity:1;
}
form.white_input .form-group button.login-button {
	color:#fff;
	background-color: transparent !important;
	border: 1px solid #fff;
	margin-top: 25px;
	cursor: pointer;
}
form.white_input h3{
	color: #fff;
	font-weight: 600;
	margin-bottom: 25px;
	margin-top:25px;
}
form.white_input .input-group-prepend .input-group-text{
	background-color: #fff;
	color: rgb(133,133,133);
}
form.white_input .form-check-label{
	color: #fff;
  margin-left: 20px;
  margin-top:17px;
}
form.white_input .form-check{
	margin-top: 25px;
}
form.white_input .form-check-input{
  position: absolute;
  top: 5px;
  left: 12px;
  height: 35px !important;
  width: 50px !important;
  background-color: #eee;
}

:focus {
  outline: none !important;
}

.plan-attachment-row{
  display: flex;
  flex-direction: row;
  width:auto;
}

.plan-attachment:hover{
  cursor: pointer;
  opacity: 0.7;
}

.enterprise-styled-button:hover{
  background-color: var(--enterprise-primary-color) !important;
  color: #fff !important;
  font-weight: bold !important;
}

.draftJsHashtagPlugin__hashtag__1wMVC{
  color:red;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgb(255, 0, 0,1);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgb(255, 0, 0,0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgb(255, 0, 0,0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 0, 0,1);
    box-shadow: 0 0 0 0 rgba(255, 0, 0,.5);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(255, 0, 0,0);
      box-shadow: 0 0 0 10px rgba(255, 0, 0,0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(255, 0, 0,0);
      box-shadow: 0 0 0 0 rgba(255, 0, 0,0);
  }
}



/* FORM END*/
/* notification */
.notification-success{
    background-color: #abd810;
}
.notification-error{
    background-color: #ED5652 !important;
}
/*notification end*/

@media print {
  span, h3{
    page-break-inside: avoid;
  }
  #printButton{
    display: none;
  }
  #main-container{
    padding-top:0 !important;
  }
  #plan-container{
    margin-top: 0 !important;
  }
  body {
    background-image: none;
    color-adjust: exact; 
    -webkit-print-color-adjust: exact;
  }
  .annotation-tool-icon{
    display: none;
  }
  .annotation-container {
    display: none;
  }
  #planItemPreview{
    display:none;
  }
}    