.backgroundimage{
	width: 100%;
	height: 100% !important;
	position: inherit;
	background-image: url('../../assets/images/loginbg.jpg');
  	background-position: center;
  	background-size: cover;
		background-repeat: no-repeat;	
}
.logo{
	width: 100px;
	height: 100px;
	background-image: url('../../assets/images/qibbie_logo.png');
  	background-position: center;
  	background-size: contain;
  	background-repeat: no-repeat;
  	margin: auto;
  	margin-bottom: 25px;
}
.login-button {
  color: #ffffff;
  background-color: rgba(119, 119, 119, 1) !important;
  width: 100%;
}

.login-button:hover {
  background-color: rgb(80, 80, 80) !important;
}

#login-form {
  width: 400px;
}

.password-forgotten {
  cursor: pointer;
  
}

@media only screen and (max-width: 768px) {
  .login-button {
    width: 100%;
  }
}

@media only screen and (max-width: 600px){
	.loginbox{
		height: 100% !important;
		/* padding: 50px 0px 50px 0px !important; */
	}
}