.project-timeline-sticky{
    position: sticky;
    top: 0;
    z-index: 9;
    background: #fff !important;
    border-radius: 10px 0 0 0;
    
}

.project-timeline-container{
    background:#fff;
    padding:0em;
    border-radius: 10px;
}

.react-calendar-timeline .rct-dateHeader-primary{
    color: #404040 !important;
    font-weight: bold;
}

.react-calendar-timeline .rct-calendar-header {
    border-left: 1px solid #bbb;
}

.rct-item{
    border: 1px solid #404040 !important;
    color: #404040;
    border-radius: 4px;
    font-weight: bold;
    z-index: 1 !important;
    /* background: #f3be2170 !important; */
}
.react-calendar-timeline .rct-vertical-lines .rct-vl {
    z-index:1 !important;
}

.custom-group{
    display: flex;
    flex-direction: column;
    padding-left: 0.25em;
}

.lower-date-time-div{
    text-align: center;
    border-left: 1px solid #c8c8c8;
    height:100%;
    cursor:pointer;
    margin-left:-1px;
}

.date-is-today{
    background: rgb(233, 1, 1);
    opacity: 0.9;
    font-weight: bold;
    color: #fff;
    z-index:2
}

.DayPicker{
    z-index: 1001;
}

.timeline-div-button{
    height:2.25em;
    display:flex;
    justify-content:center;
    align-items:center; 
    cursor:pointer;
    /* margin-top:0.25em; */
}

.timeline-modal-card{
    background-color: #ebebeb;
    border-radius: 10px;
    margin-top: 0.25em;
    padding: 0.5em;
    cursor: default;
}
.timeline-modal-card:hover{
    /* opacity:0.8; */
    background-color: #ccc;
}

.timeline-card-duedate{
    display: flex;
    padding: 0.25em 0.5em;
    border-radius: 10px;
    font-size: 0.8rem;
    align-items: center;
    margin-left: auto;
    border: 1px solid #bbb;
    font-weight: bold;
}

.member-tag{
    font-size:0.8rem;
    padding: 0em 0.3em;
    background-color: rgb(139, 211, 250);
    border: 1px solid #bbb;
    border-radius: 10px;
    font-weight: bold;
    margin-right: 0.25em;
}

.timeline-trello-label{
    font-size:0.8rem;
    padding: 0em 0.3em;
    height: 20px;
    border: 1px solid #bbb;
    border-radius: 10px;
    font-weight: bold;
    margin-right: 0.25em;
}