@keyframes flickerDot {
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
}
@-o-keyframes flickerDot {
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
}
@-moz-keyframes flickerDot {
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
}
@-webkit-keyframes flickerDot {
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
}
.animate-flicker-dot {
    -webkit-animation: flickerDot 1s infinite;
    -moz-animation: flickerDot 1s infinite;
    -o-animation: flickerDot 1s infinite;
    animation: flickerDot 1s infinite;
}