.error {
  border: 1px solid red;
}
.logo{
	width: 100px;
	height: 100px;
	background-image: url('../../assets/images/qibbie_logo.png');
  	background-position: center;
  	background-size: contain;
  	background-repeat: no-repeat;
  	margin: auto;
  	margin-bottom: 25px;
}
.registerBackground{
	width: 100%;
	height: 100%;
	min-height: 100%;
	position: inherit;
	background-image: url('../../assets/images/loginbg.jpg');
  	background-position: center;
  	background-size: cover;
  	background-repeat: no-repeat;
}

@media only screen and (max-width: 600px){
	.greybox{
		height: 100% !important;
		color: transparent;
	}
}
@media only screen and (max-height: 1024px){
	.registerBackground{
		height: auto;
	}
}