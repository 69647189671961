.gantt-row-odd{
    background-color: #f5f5f5;
}

.gantt-row-even{
    background-color: #fff;
}

.gantt-row-styles{
    display: flex;
    list-style: none;
    margin: 0;
    outline: none;
    padding: 0;
    position: relative;
    overflow: hidden;   
}

.gantt-row-styles:hover{
   background-color: #ebebeb;
}

.tag-display{
    text-align: left;
    font: normal normal 9px/10px Raleway;
    letter-spacing: 0px;
    color: #292929;
    padding: 0.25em 0.5em 0.25em 0.5em;
    border-radius: 10px;
    opacity: 1;
    background: #CCE4E870;
    margin-right: 0.25em;
}
  
.tag-text{
    align-self: center;
    white-space:nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 8em;
}

.search-bar-input {
	outline: none;
}

.search-bar-input[type=search] {
	-webkit-appearance: textfield;
  appearance: textfield;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
	font-family: inherit;
	font-size: 100%;
}

.search-bar-input::-webkit-search-decoration,
.search-bar-input::-webkit-search-cancel-button {
        display: none; 
}

.search-bar-input[type=search] {
	background: transparent url(../../../../assets/search-icon.png) no-repeat 9px center;
    border: solid transparent;
    border-width: 0px 0px 1px 0px;
	padding: 0px 10px 0px 32px;
    width: 55px;
	
	/* -webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px; */
	
	-webkit-transition: all .5s;
	-moz-transition: all .5s;
	transition: all .5s;
}

.search-bar-input[type=search]:focus {
	width: 130px;
    background-color: transparent;
    border: solid ;
    border-width: 0px 0px 1px 0px;
  border-color: #000;
  
	
	/* -webkit-box-shadow: 0 0 5px rgba(109,207,246,.5);
	-moz-box-shadow: 0 0 5px rgba(109,207,246,.5);
	box-shadow: 0 0 5px rgba(109,207,246,.5); */
}

.search-bar-input:focus::-webkit-input-placeholder { color:#999 !important; }
.search-bar-input:focus::-moz-placeholder  { color:#999 !important; }/* FF 4-18 */
.search-bar-input:focus::-moz-placeholder { color:#999 !important; } /* FF 19+ */
.search-bar-input:focus::-ms-input-placeholder { color:#999 !important; } /* IE 10+ */
