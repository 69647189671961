.codeinput-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .codeinput-row {
    width: 450px;
  }
  
  .code-cell {
    display: inline-block;
    margin: 5px;
    flex: 1 1 auto;
    width: 1%;
    font-size: xx-large;
    height: 2.3em;
    text-transform: uppercase;
  }
  
  @media only screen and (max-width: 768px) {
    .codeinput-row {
      width: 100%;
    }
  }