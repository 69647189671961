/* ASK TO CONTINUE MODAL */

.modal-button {
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s;
}

.modal-accept-button {
  background-color: rgb(181, 228, 19);
  border-color: rgb(181, 228, 19);
}

.modal-accept-button:hover {
  background-color: rgb(165, 208, 16);
  border-color: rgb(165, 208, 16);
}

.modal-new-button {
  background-color: #808080;
  border-color: #808080;
}

.modal-new-button:hover {
  background-color: #636363;
  border-color: #636363;
}


/* ASK TO CONTINUE MODAL END */

/* SURVEY */

.survey-nav-row {
  position: absolute;
  bottom: -2rem;
  left: 0;
  background-color: #fff;
  width: 100%;
}

.survey-nav-ball {
  border-radius: 15%;
  height: 10px;
  width: 50px;
  display: inline-block;
  margin-right: 0.5rem;
  border: 1px solid;
}

.infoblock {
  font-size: 2em;
  z-index: 10;
}

.small-infoblock {
  font-size: 1rem;
}

.infoModal {
  display: block;
  margin: 15% auto !important;
  z-index: 10040;
  overflow: auto;
  overflow-y: auto;
}

.infoModal .modal-header {
  padding: .5rem 1rem !important;
}

.instafb-editor {
  margin: 2em 0 2.5em;
}

.icon-answers {
  margin-bottom: 2em;
}

.feedback-row {
  margin: 0 1em !important;
}

.dialogInfoBlock {
  position: absolute;
  right: -0.3em;
  top: -0.5em;
  color: rgb(255, 204, 0);
  z-index: 99;
  animation: pulse 2s infinite;
  box-shadow: 0 0 0 rgba(204,169,44, 0.4);
  cursor: pointer;
  border-radius: 50%;
  font-size: 1.5em;
}

.CalendarDay__today {
  background-color: aliceblue;
}

.CalendarDay__selected{
  background-color: #00a699 !important;
}

.top-ribbon {
  border-top: 2px solid;
  border-bottom: 2px solid;
  background-color: #fff;
  width: 100%;
}

.info-ribbon {
  color:#fff;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
    box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
      box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
      box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}

.survey-group-title-container {
  display: flex;
  flex: 0 84%;
}

.answers-container {
  min-width: 100%;
  bottom: 0;
  left: 0;
  position: relative;
  padding: 2em 0 0 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  overflow: hidden;
  min-height: 50%;
  align-items: flex-end;
}

.make-padding-great-again {
  padding: 3rem 0 0 0 !important;
}

.p1 {
  padding: 1rem;
}

@media (min-width: 768px) {
  .survey-group-title-container {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .survey-group-title-container {
    justify-content: left;
    text-align: left;
  }

  .d-none-768 {
    display: none;
  }

  .dialogInfoBlock {
    font-size: 1.2em;
  }

  .feedback-row {
    margin: 0 !important;
  }
}
/* SURVEY END */