.check-item-title{
    width: 100%;
  }

  .check-item-row{
    display:flex;
    /* align-items: center; */
  }

  .add-new-checkItem-row{
    display: flex;
  }

  .check-item-box{
    width: 1.5em;
    height: 1.5em;
    margin-top: 0.2em;
    cursor: pointer;
  }

  .check-item-box:focus{
     -webkit-appearance:checkbox !important;
  }
  .check-item-row{
    padding-top: 0.25em;
    display:flex;
    margin-left: -1em;
    border-radius: 5px !important;
    /* align-items: center; */
  }

  .check-item-row:hover{
    background-color: #eeeeee;
  }