.robot {
  width: 100px;
  height: 200px;
  background-repeat: no-repeat;
  background-size: contain;
  float: left;
}

.robot-left {
  width: 100px;
  height: 200px;
  background-repeat: no-repeat;
  background-size: contain;
  float: right;
}

.robot-dialog {
  margin: -2em 0 3em 100px;
  text-align: left;
  color: #333;
  background: #ffffff;
  border-radius: 10px 10px 10px 0;
  padding: 20px;
  position: absolute;
  bottom: 5rem;
}

.robot-dialog-left {
  margin: 0em 110px 3em -3em;
  text-align: center;
  color: #333;
  background: #ffffff;
  border-radius: 10px 10px 0 10px;
}

.robot-dialog-title {
  margin-top: 0;
}

.robot-dialog-text {

}
.qibbie-dude .wrapper {
  position: relative;
  height: 100px;
}