.bottom-separator{
    width: 30%;
    height: 0.25em;
    border-radius: 5px;
    
}

.bottom-row{
    margin-top: 1em;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.sheet-survey-editor{
    min-height: 5em;
}

.center-in-sheet{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
}

/* Here is defined the addImageButton position in sheet survey.
Note that it is relative to the editor position (or rather the container position)
Therefore it is essential to adjust both sheet-editor and addImageButton to visually match wanted scenario */
.sheet-editor .addImageButton{
    top:4.25em;
}

.sheet-editor{
    margin-top: 1em;
}

.sheet-question-info{
    position:absolute;
    align-items: center;
    top:0.5em;
    right: 0.5em;
    border: 2px solid var(--highlight-color);
    padding: 0.25em 0.75em 0.25em 0.75em;
    border-radius: 50%;
    cursor: pointer;
    background-color: #fff;
}

.sheet-question-info:hover{
    opacity: 0.9;
    box-shadow: 0px 1px 4px !important;
}
.sheet-logo-div{
    margin-top: 0.25em;
    max-height: 70px;
    z-index:10;
}

.copy-automation-link{
    margin-left: 2em;
    font-size: 1.5rem;
    margin-top: -0.25em;
    cursor: pointer;
    /* color: #007bff; */
    border-radius: 10px;
    background-color: #fefefe;
    border: 2px solid #FFCC00;
    padding-left: 0.5em;
    padding-right: 0.5em;
    box-shadow: 1px 1px 4px #c4c4c4 !important;
}

.copy-automation-link:hover{
    opacity: 0.9;
    box-shadow: 1px 1px 4px #FFCC00 !important;
}

.sheet-item-col{
    padding-top: 2em !important;
    padding-bottom: 2em !important;
    padding-left: 2em !important;
    padding-right: 2em !important;
}

.send-feedback-button{
    margin-top: -2.5em;
    border-radius: 5px;
    text-align: right;  
    display:flex;
    flex-direction: column;
    align-content: flex-end;
    justify-content: flex-end;
    max-width: max-content;
    margin-left:auto;
}
.sheet-email-sender{
    color: var(--enterprise-primary-color);
    cursor: pointer;
}

.triangle{
    width: 1px; 
    height: 1px; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #FFCC00;
    /* float:right; */
    margin-left:auto;
    margin-right:1em;
    margin-top: 0.25em;
}

.feedback-info{
    border: 2px solid #FFCC00;
    border-radius: 5px;
    padding: 0.25em;
    font-weight: bold;
}

.sheet-survey-ended-container{
    text-align: left;
}

.empty-answer{
    border: 2px solid red;
    margin-top: -2px;
}

.feedback-header{
    padding-bottom: 3em !important;
}

.sheet-header{
    font-weight: bold;
}

.sheet-question-header-row{
    display: flex;
    flex-direction: row;
    padding-bottom: 1em;
}

.survey-canvas{
    box-shadow: 2px 2px 6px #d2d2d2;
    background-color: #fefefe !important;
    
}

.freetext-answer {
    background-color: #fff !important  ;
}

.email-check-box{
    position:absolute;
    margin-top: 0.4em;
    right: 14em;
    left:auto;
}

.sheet-submit-div{
    /* position:fixed;
    bottom: 2em;
    right: 2em; */
    width: 100%;
    height:auto;
    padding-top: 1em;
    padding-bottom: 3em !important;
    z-index: 11;
    
}

.submit-sheetData-button{
    background-color: var(--enterprise-highlight-color) !important;
    color: #fff !important;
    font-weight: bold !important;
    font-size: 1.5rem;
    box-shadow: 2px 2px 6px #d2d2d2 !important;
}

.sheet-even-row{
    background-color: #efefef;
}

.sheet-option-button{
    min-width: 2em;
    min-height: 2em;
    max-width: 2em;
    max-height: 2em;
    border-radius: 50% !important;
}

.sheet-option-row{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-bottom: 0.5em;
}
.sheet-option-text{
    margin-left: 1em;
    text-align: left;
    font-size: 0.9rem;
}

.scroller-container{
    position: fixed;
    bottom: 1em;
    right: 1em;
    z-index: 12;
    display: flex;
    flex-direction: column;
    text-align: center;
    box-shadow: 2px 2px 4px #acacac;
    border-radius: 50px ;
    /* border: 1px solid #404040; */
 
}

.scroll-button{
    display: flex;
    align-content: center;
    justify-content: center;
    font-size: 1.5rem;
    background-color: var(--enterprise-highlight-color);
    color: #fff;
    padding-left: 0.5em;
    padding-right: 0.5em;
    cursor: pointer;
    border: 1px solid #40404040;
}

.to-top-button{
    border-radius: 20px 20px 0 0; 
    padding-top: 0.25em;
}

.scroll-button :hover{
    opacity: 0.8 !important;
}

.to-bottom-button{
    border-radius: 0 0 20px 20px;
    padding-bottom: 0.25em;
    
}

.sheet-group-description{
   text-align: left;
}

/* ------------- sheet nav css -------------*/

.sheet-nav-container{
    display: flex;
    flex-direction: column;
    background-color: #fefefe;
    border-radius: 0 0 10px 0;
    z-index: 12;
    overflow-x: hidden !important;
    align-content: center !important;
    justify-content: center !important;
    width: 12em;
    height: 89%;
    border-top: 3px solid var(--enterprise-highlight-color);
    border-right: 3px solid var(--enterprise-highlight-color);
    border-bottom: 3px solid var(--enterprise-highlight-color);
    
}

.sheet-nav-item{
    padding: 0.25em;
    padding-left: 0.5em;
    margin-bottom: 0.5em;
    border-radius: 10px;
    cursor: pointer;
    max-width:10em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    height: 2em;
}

.sheet-nav-button{
    background-color: var(--enterprise-highlight-color) !important;
    border:hidden !important;
    align-items: center;
    width: 2em !important;
    height: 2em !important;
    border-radius: 0 10px 10px 0;
    z-index:12;
    box-shadow: 1px 1px 4px #ccc;
    margin-left: -1px;
}

.sheet-nav-closed{
    position: fixed;
    top: 5em;
    left: 0em;
}

.sheet-nav-open{
    position: fixed;
    top: 5em;
    left: 12em;
}

.sheet-nav-container-closed{
    position: fixed;
    top: 5em;
    left: -10em;
}

.sheet-nav-container-open{
    position: fixed;
    top: 5em;
    left: 0em;
}

.sheet-nav-peek .sheet-nav-closed{
    left: 0em;
    border-radius: 0 10px 0 0;
}
.sheet-nav-peek .sheet-nav-container-closed{
    left: -10em;
    box-shadow: 1px 2px 4px #ccc;
    border-radius: 0 10px 10px 0;
}

.sheet-nav-question-title{
    font-size: 0.8rem;
}
.nav-group-header {
    max-width:10em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.sheet-nav-small{
    height: 100%;
    margin-top: 2em;
    width: 2em;
    align-self: flex-end;
    text-align: center;
    overflow: hidden;
}

.nav-not-answered-icon{
    position: relative;
    font-size: 0.8rem;
    top: -1em;
    right: -0.5em;
    color: red;
}

.sideNav-not-answered-icon{
    position: relative;
    font-size: 0.8rem;
    top: -1em;
    right: -10.5em;
    color: red;
}

@media only screen and (min-width: 1135px) and (max-width: 1500px) {
    .home-container{
        margin: 0 0 0 11.75em !important;
    }
}

@media only screen and (max-width: 1024px) {

    .home-container{
        width: 100% !important;
        max-width: 100% !important;
        margin: 3em 0 0 0 !important;
    }

}

@media only screen and (max-width: 824px) {

    .home-container{
        width: 100% !important;
        max-width: 100% !important;
        margin: 1em 0 0 0 !important;
    }

    .sheet-nav-container{
        top: 3em !important;
    }
    .sheet-nav-closed{
        position: fixed;
        top: 3em;
        left: 0em;
    }
    
    .sheet-nav-open{
        position: fixed;
        top: 3em;
        left: 12em;
    }
    
    .sheet-logo-div{
        min-width: 40px !important;
    }

    .sheet-header{
        font-size: 1.75rem !important;
    }
    
}
@media only screen and (max-width: 650px) {
    .sheet-header-balance{
        display:none;
    }
    .send-feedback-button{
        margin-top: 0 !important;   
        border-radius: 5px;
        /* border: 2px solid #FFCC00; */
        /* padding: 0.5em; */
        text-align: center !important;  
        display:flex;
        flex-direction: column-reverse;
        align-content: center !important;
        justify-content: center !important;
        margin-bottom: 0em !important;
        margin-left: auto;
        margin-right: auto;
    }

    .feedback-header{
        padding-bottom: 1em !important;
    }

    .sheet-email-sender{
        color: var(--enterprise-primary-color);
        cursor: pointer;
    }

    .triangle{
        width: 1px; 
        height: 1px; 
        border-right: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-top: 5px solid #FFCC00;
        border-left: 5px solid transparent;
        float:unset;
        /* margin-left:0 !important; */
        margin-right:auto;
        margin-top: 0px;
    }

    .feedback-info{
        margin-top: 0.75em;
        border: 2px solid #FFCC00;
        border-radius: 5px;
        padding: 0.25em;
        font-weight: bold;
        font-size: 0.8rem;
        height: 2.5em;
        width:max-content;
    }

}