.verified-by{
    font-style: italic;
    position: absolute;
    bottom: 2em;
    display: flex;
    align-items: center;
    width: 100%;
}

.signature-verification-container{
    height: 100%;
    align-items: center;
}
.signature-verification-block{
    display: flex;
    flex-direction:column;
    padding-left:0.5em; 
    padding-top: 0.25em; 
    padding-bottom: 0.25em; 
    margin-top: 0.25em;
    margin-bottom:0.75em;
}
