.first-login-button {
  width: 100%;
}
.backgroundimage{
	background-image: url('../../assets/images/loginbg.jpg');
  	background-position: center;
  	background-size: cover;
  	background-repeat: no-repeat;
}
.first-login-box{
	box-shadow: 7px 10px 6px -4px rgba(74,74,74,.66);
  padding: 50px !important;
  background-color: rgba(132,152,173,.6);
}